<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import BaseAntScanner from '@/components/Base/BaseAntScanner.vue';
import { getModuleData } from '@/services/api/module.api';
import { mapGetters } from 'vuex';
import { DHME_M_YARD_MANAGEMENT, DHME_QR2FORM } from '@/modules/modules';
import axios from 'axios';
import { createRecord } from '@/services/api/record.api';

export default defineComponent({
  name: 'DHMEQR2Form',
  components: { BaseAntScanner, ModuleNavigationBar },
  data: () => {
    return {
      moduleData: null,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser', 'project', 'selectedSession', 'isDaiwaLicense']),
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_QR2FORM
      ).id;
    },

    elements() {
      return this.moduleData.CFFA_DHME_ELEMENTS.records;
    },
    scanLogItems() {
      return this.moduleData.CFFA_DHME_QR_SCAN_LF_LOG.records;
    },
  },
  async mounted() {
    this.moduleData = await getModuleData(
      this.project.id,
      this.moduleId,
      this.$route.params.sessionId ?? null
    );
  },
  methods: {
    decoder(value) {
      return JSON.parse(value);
    },
    scanResult(data) {
      // check if log item exists
      let logItem = this.scanLogItems.find(
        (record) => record.production_id === data.production_id
      );
      if (logItem) {
        this.authenticateForm(logItem.lf_guid);
      } else {
        this.startForm(data.production_id, data.element_type);
      }
    },
    async authenticateForm(formId) {
      const body = {
        credentials: {
          username: 'dhme_ant',
          password: '5<X>CgX}9N]bT8EGLIw%',
          client_id: '31137B4A-679D-405C-9C53-F8ADF5294EC5',
          client_secret: 't7S#z]t<d)>$cY@',
        },
        projectid: this.project.id,
        user: this.authenticatedUser.email,
      };

      try {
        let response = await axios.post(
          `https://dhme.leanbms.nl/webservice/project/redirect?url=Formulier.aspx%3Fguid%3D${formId}`,
          body
        );

        window.open(response.data.link, '_blank');
      } catch (e) {
        this.$store.commit('showNotification', {
          content:
            'Something went wrong while opening Leanforms form, please contact an admin',
          color: 'error',
        });
      }
    },
    async startForm(productionId, elementType) {
      const body = {
        credentials: {
          username: 'dhme_ant',
          password: '5<X>CgX}9N]bT8EGLIw%',
          client_id: '31137B4A-679D-405C-9C53-F8ADF5294EC5',
          client_secret: 't7S#z]t<d)>$cY@',
        },
        projectid: this.project.number,
        formtypeid: 2486,
        user: {
          firstname: this.authenticatedUser.firstname,
          lastname: this.authenticatedUser.lastname,
          email: this.authenticatedUser.email,
        },
        data: {
          company: this.authenticatedUser.company.name,
          project: this.project.number,
          module: null,
          q_130404: productionId,
          access_token: null,
          log_id: null,
          project_id: this.project.id,
          fase: null,
          q_70179: this.project.number,
          q_130411: elementType,
        },
      };

      try {
        let response = await axios.post(
          'https://dhme.leanbms.nl/webservice/form/start',
          body
        );

        let logBody = {
          project: {
            id: this.project.id,
          },
          table: {
            id: this.moduleData.CFFA_DHME_QR_SCAN_LF_LOG.id,
          },
          lf_guid: response.data.formid,
          production_id: productionId,
        };

        let record = await createRecord(logBody);
        this.moduleData.CFFA_DHME_QR_SCAN_LF_LOG.records.push(record);

        await this.authenticateForm(record.lf_guid);
      } catch (e) {
        this.$store.commit('showNotification', {
          content:
            'Something went wrong while starting the leanforms form, please contact an admin',
          color: 'error',
        });
      }
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="QR 2 Form">
      <template #module-nav-logo>
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-column align-center justify-center">
      <div class="mb-5 fs-20">Please scan a valid QR code</div>
      <div class="ant-glass-background pa-5" style="width: 800px">
        <base-ant-scanner
          :decode-parser="decoder"
          @decodedResult="scanResult"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
